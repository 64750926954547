*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

body {
  color: #121212;
  background-color: #fff;
  font-family: Courier New, Courier, monospace;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background-color: #121212;
  }
}

a {
  color: #121212;
}

@media (prefers-color-scheme: dark) {
  a {
    color: #fff;
  }
}

button {
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 0;
  position: relative;
  bottom: 20px;
  left: 20px;
}

img {
  width: 2.5rem;
  max-width: 100%;
  height: auto;
}

.content {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  font-size: 2.5rem;
  font-weight: 800;
  display: flex;
}

.content .section {
  color: #0000;
  background: linear-gradient(45deg, #ff0047, #ffa600, #1e90ff) text;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

@media (width >= 600px) {
  .content .section {
    flex-flow: row;
  }
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.waving-hand {
  color: #ff0047;
  padding-right: 20px;
  animation: 1.2s ease-in-out infinite wave;
  display: inline-block;
}

#menu {
  border-radius: 5px;
  position: fixed;
  bottom: 75px;
  left: 20px;
  box-shadow: 0 4px 8px #0000004d;
}

@media (prefers-color-scheme: dark) {
  #menu {
    box-shadow: 0 4px 8px #ffffff4d;
  }
}

#menu ul {
  padding: 0;
  list-style-type: none;
}

#menu ul li {
  margin: 15px;
}

#menu ul li a {
  text-decoration: none;
}

@keyframes wave {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(0);
  }

  75% {
    transform: rotate(-15deg);
  }

  100% {
    transform: rotate(0);
  }
}
/*# sourceMappingURL=index.70c24c24.css.map */
