$vivid-red: #ff0047;
$gold: #ffa600;
$dodger-blue: #1e90ff;
$dark-charcoal: #121212;

body {
    font-family: 'Courier New', Courier, monospace;
    color: $dark-charcoal;
    background-color: white;

    /* Dark mode background color */
    @media (prefers-color-scheme: dark) {
        color: white;
        background-color: $dark-charcoal;
    }
}

a {
    color: $dark-charcoal;

    /* Dark mode background color */
    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

button {
    position: relative;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
}

img {
    max-width: 100%;
    width: 2.5rem;
    height: auto;
}

.content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: 800;
    padding: 0 50px;

    .section {
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background: linear-gradient(45deg, $vivid-red, $gold, $dodger-blue);
        background-clip: text;
        color: transparent;
        flex-flow: column;

        /* Portrait and Landscape */
        @media (min-width: 600px)  { // this is the breakpoint that fits the 2 divs nicely
            flex-flow: row;
        }
    }
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.waving-hand {
    display: inline-block;
    color: $vivid-red;
    animation: wave 1.2s infinite ease-in-out;
    padding-right: 20px;
}

#menu {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: fixed;
    border-radius: 5px;
    bottom: 75px;
    left: 20px;

    /* Dark mode background color */
    @media (prefers-color-scheme: dark) {
        box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3);
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            margin: 15px;

            a {
                text-decoration: none;
            }
        }
    }
}

@keyframes wave {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(15deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-15deg); }
    100% { transform: rotate(0deg); }
}
